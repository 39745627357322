import {parseUriQuery} from '../web'
import {doWhen} from '../utils'
import {getCacheItem, setCacheItem} from '../cache'
import {updateRequest, getRequestId} from '../Mapi'

export function run() {
  doWhen(
    () => getRequestId(),
    () => {
      const qp = parseUriQuery()
      if (!qp) return
      // convert all the properties to lowercase as they may come in as upercase
      const query = Object.entries(qp).reduce((carry, [key, value]) => ({...carry, [key.toLowerCase()]: value}), {})

      const {tid, tprovider, tkeyword = null} = query

      if (!tprovider || !tid) return

      const adComData = {tid, tprovider, tkeyword}

      const cached = getCacheItem('mapi_sent_adcom')
      if (cached && cached[tid]) {
        return
      }

      updateRequest(getRequestId(), {adcom_data: adComData}, () => {
        const sent = getCacheItem('mapi_sent_adcom') || {}
        sent[tid] = true
        setCacheItem('mapi_sent_adcom', sent)
      })
    },
  )
}
