/* eslint-disable prefer-rest-params */
import {firstIndex, each} from './utils'

const ENV_DEV = 'development'
const ENV_STAGING = 'staging'
const ENV_PRODUCTION = 'production'

const levels = {
  debug: 100,
  info: 200,
  notice: 250,
  error: 300,
  critical: 500,
}

const LEVEL_DEBUG = 'debug'
const LEVEL_INFO = 'info'
const LEVEL_NOTICE = 'notice'
const LEVEL_ERROR = 'error'
const LEVEL_CRITICAL = 'critical'

const clLog = []

const mapiUrls = {
  [ENV_DEV]: '//mapi.loc',
  [ENV_STAGING]: 'https://mapi.stage.clear.link',
  [ENV_PRODUCTION]: 'https://m.clear.link',
}

const tcpaUrls = {
  [ENV_DEV]: 'https://proof.dev.aws.clear.link',
  [ENV_STAGING]: 'https://proof.dev.aws.clear.link',
  [ENV_PRODUCTION]: 'https://proof.clear.link',
}

function getLogLevel() {
  return (global.MAPI && global.MAPI.logLevel) || levels[LEVEL_ERROR]
}

function pushToInMemoryLog(level, args) {
  const parsed = []
  each(args, a => parsed.push(a))

  clLog.push([level, parsed])
}

function nameFromLevel(level) {
  return firstIndex(levels, l => l === level) || 'none'
}

function log(level, args, force = false) {
  if (!force || getLogLevel() >= level) return
  // eslint-disable-next-line no-console
  if (!console || !console.error) return

  pushToInMemoryLog(level, args)

  const logArgs = [`[${nameFromLevel(level)}]`]
  each(args, a => logArgs.push(a))

  try {
    // eslint-disable-next-line no-console
    console.log(...logArgs)
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function debug() {
  log(100, arguments)
}

function info() {
  log(200, arguments)
}

function notice() {
  log(250, arguments)
}

function error() {
  log(300, arguments)
}

function critical() {
  log(500, arguments)
}

function print() {
  log(0, arguments, true)
}

function isValidEnv(value) {
  return value !== undefined && [ENV_DEV, ENV_STAGING, ENV_PRODUCTION].indexOf(value) !== -1
}

// set the global environment. not ideal to be accessing `global` here, but since
// multiple entrypoints access the MAPI env, we need it to be consistent
function setEnv(value) {
  if (!isValidEnv(value)) return
  global.MAPI_ENV = value
}

function getEnv() {
  return global.FORCE_MAPI_ENV || global.MAPI_ENV
}

/**
 * Determine the correct MAPI url. Checks a global, then tries to determine the correct MAPI url
 * given the current window.location.
 *
 * @returns string
 */
function getMapiUrl(environment = getEnv()) {
  if (!isValidEnv(environment)) return mapiUrls[ENV_PRODUCTION]
  return mapiUrls[environment]
}

function getTcpaUrl(environment = null) {
  return isValidEnv(environment) ? tcpaUrls[environment] : tcpaUrls[ENV_PRODUCTION]
}

function normalizeLogLevel(nameOrLevel) {
  return levels[nameOrLevel] || nameOrLevel
}

function setLogLevel(l) {
  global.MAPI = global.MAPI || {}
  global.MAPI.logLevel = normalizeLogLevel(l)
  // eslint-disable-next-line no-console
  console.log(`set log level to ${global.MAPI.logLevel}`)
  return l
}

function getFullLog() {
  return clLog
}

global.setLogLevel = setLogLevel
global.getLogLevel = getLogLevel

function allowFingerprintSend() {
  return false
}
function setNoFingerprintAllowed() {}

export {
  ENV_DEV,
  ENV_PRODUCTION,
  ENV_STAGING,
  levels as logLevels,
  LEVEL_DEBUG,
  LEVEL_INFO,
  LEVEL_NOTICE,
  LEVEL_ERROR,
  LEVEL_CRITICAL,
  debug,
  info,
  notice,
  error,
  critical,
  print,
  setEnv,
  getEnv,
  getMapiUrl,
  getTcpaUrl,
  setLogLevel,
  getFullLog,
  allowFingerprintSend,
  setNoFingerprintAllowed,
}
