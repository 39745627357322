import {parseUriQuery} from '../web'
import {doWhenP} from '../utils'
import {updateRequest, getRequestId} from '../Mapi'
import {getCacheItem, setCacheItem} from '../cache'

const CACHED_ID_KEY = 'google_gclid'
const CACHED_SRC_KEY = 'google_gclsrc'

function updateGclData() {
  const requestId = getRequestId()
  const {gclid, gclsrc} = parseUriQuery() || {}
  const cachedId = getCacheItem(CACHED_ID_KEY) || null
  const cachedSrc = getCacheItem(CACHED_SRC_KEY) || null
  const data = {
    request_id: requestId,
    gclid,
    gclsrc,
  }

  if (!gclid || !gclsrc || (cachedId === gclid && cachedSrc === gclsrc)) return

  updateRequest(requestId, {google_gcl_data: data}).then(() => {
    setCacheItem(CACHED_ID_KEY, gclid)
    setCacheItem(CACHED_SRC_KEY, gclsrc)
  })
}

export function run() {
  return doWhenP(() => !!getRequestId()).then(updateGclData)
}
