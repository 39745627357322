import {error} from '../Env'
import {doWhenP} from '../utils'
import {cookie, getStrippedDomain} from '../web'
import {getCacheItem, setCacheItem} from '../cache'
import {getRequestId, updateRequest} from '../Mapi'

export interface VisitorData {
  request_id: string
  visitor_id: string
}

export const VISITOR_CACHE_KEY = 'sent_visitor_id'
export const VISITOR_ID_COOKIE = 'clVisitorId'

export async function sendVisitorId(requestId: string) {
  if (getCacheItem(VISITOR_CACHE_KEY)) return

  const curVisitorId = cookie(VISITOR_ID_COOKIE)
  const visitor_id: VisitorData = {
    request_id: requestId,
    visitor_id: curVisitorId,
  }

  const res = await updateRequest(requestId, {visitor_id})

  setCacheItem(VISITOR_CACHE_KEY, true)
  const returnedVisitorId: string | undefined = res?.data?.visitor_id?.data?.visitor_id

  if (returnedVisitorId && returnedVisitorId !== curVisitorId) {
    cookie(VISITOR_ID_COOKIE, returnedVisitorId, 86400 * 365 * 5, null, getStrippedDomain())
  }
}

/**
 * VisitorId is an extremely long-lived per-domain cookie.
 * It's used to identify return visitors to the same website.
 * */
export function run(expiration = 10000, interval = 500) {
  return doWhenP(() => getRequestId(), interval, expiration, false)
    .then(requestId => sendVisitorId(requestId))
    .catch(e => {
      error('error updating visitor ID', e)
    })
}
