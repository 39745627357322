/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line import/no-cycle
import {getRequestId, getPromoCode} from './Mapi'
import {get} from './utils'
import EventEmitter from './EventEmitter'

// Used for the data required for rotation, without bringing in the full replacement
// code. This way, we can access the brand / promo / etc in a unified way from both the
// mapi-full and the rotation build
// eslint-disable-next-line import/prefer-default-export
export class RotationConfig {
  constructor(config) {
    this._conf = config || {}
    this.events = new EventEmitter()
  }

  setConfig(conf) {
    this._conf = conf
  }

  brand() {
    return this._conf.brand || ''
  }

  defaultToken() {
    return this._conf.defaultToken || this._conf.brand
  }

  domain() {
    return this._conf.forceDomain || `${global.location.hostname}`.replace(/^www\./, '')
  }

  path() {
    return this._conf.forcePath || global.location.pathname
  }

  promo() {
    return this._conf.promoCode || getPromoCode()
  }

  requestId() {
    return this._conf.requestId || getRequestId()
  }

  replacementConfig() {
    return this._conf.replacement || []
  }

  watchForReplacement() {
    return this._conf.watchForNumbers
  }

  get(key, defaultValue = null) {
    return get(this._conf, key, defaultValue)
  }

  allowLastResort() {
    return typeof this._conf.allowLastResortNumber === 'undefined' ? false : this._conf.allowLastResortNumber
  }
}
