import {doWhenP} from '../utils'
import {updateRequest, getRequestId} from '../Mapi'
import {getCacheItem, setCacheItem} from '../cache'
import {cookie} from '../web'

const CACHED_KEY = 'ga_client_id'

/** @returns {string|null} */
export function getCachedClId() {
  let clientId = null
  const cache = getCacheItem(CACHED_KEY)

  if (typeof cache === 'string') {
    clientId = cache
  } else if (typeof cache === 'object' && cache.client_id) {
    clientId = cache.client_id
  }

  return clientId
}

/** @returns {string|null} */
export function getClientId() {
  let clientId = null

  const ga = cookie('_ga')
  const [id] = ga.match(/\d+\.\d+$/) || []

  if (id) {
    clientId = id
  } else if (ga && ga.length) {
    clientId = ga
  }

  return clientId
}

/** @returns {void} */
function sendClientId() {
  const requestId = getRequestId()
  const clientId = getClientId()
  const cachedData = getCacheItem(CACHED_KEY)
  const data = {
    request_id: requestId,
    client_id: clientId,
  }

  const sameReqId = typeof cachedData === 'object' && cachedData.request_id === requestId
  const sameClientId = typeof cachedData === 'object' && cachedData.client_id === clientId
  if (sameReqId && sameClientId) return

  updateRequest(requestId, {google_client_id: data}).then(() => {
    setCacheItem(CACHED_KEY, data)
  })
}

/** @returns {void} */
function handlePredicate() {
  return getClientId() && getRequestId()
}

/**
 * @param {number} [expiration=10000]
 * @param {number} [interval=500]
 * @returns {Promise<any>}
 */
export async function run(expiration = 10000, interval = 500) {
  return doWhenP(handlePredicate, interval, expiration, false)
    .then(() => sendClientId())
    .catch(e => {
      console.log('error sending ga client ID: ', e)
      return e
    })
}
