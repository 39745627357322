/* eslint-env browser */
import * as parse from 'url-parse'
import {config, map, each, zipMap, isString, filter} from './utils'
import {error} from './Env'

const ajaxDefaults = {
  GET: {
    accept: 'json',
  },
  POST: {
    accept: 'json',
    type: 'json',
    headers: {'Content-Type': 'application/json'},
  },
  PUT: {
    accept: 'json',
    type: 'json',
    headers: {'Content-Type': 'application/json'},
  },
}

/**
 * Can't rely on jQuery being present, so create our own domready wrapper. Slight nod toward IE
 * compatibility.
 *
 * @param cb
 */
function domReady(cb) {
  let listener

  if (['complete', 'loaded'].indexOf(document.readyState) !== false) {
    cb.call()
    return
  }

  if (document.addEventListener) {
    listener = () => {
      cb.call()
      document.removeEventListener('DOMContentLoaded', listener, false)
    }

    document.addEventListener('DOMContentLoaded', listener, false)
  } else if (document.attachEvent) {
    listener = () => {
      if (document.readyState !== 'complete') return

      cb.call()
      document.detachEvent('onreadystatechange', listener)
    }

    document.attachEvent('onreadystatechange', listener)
  }
}

/**
 * Encode object representation into a properly encoded query string, excluding leading '?'
 * @param obj
 * @returns {string}
 */
function encodeToQueryString(obj) {
  const encoded = map(obj || {}, (val, key) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)

  return encoded.join('&')
}

/**
 * Given a base url, construct a full URL.
 * @param {string} baseUrl string Base url, without query string
 * @param {string|string[]} [urlComponents] Components to append to the URL. E.g., ['asdf', 'qwer'] => 'asdf/qwer'
 * @param {{}} [queryComponents] Encode this as the query string.
 * @returns string
 */
function constructUrl(baseUrl = '/', urlComponents = '', queryComponents = {}) {
  // IE8 doesn't support negative indexing in String.prototype.substr
  let constructed = baseUrl.charAt(baseUrl.length - 1) === '/' ? baseUrl.substring(0, baseUrl.length - 1) : baseUrl

  let components
  if (!isString(urlComponents)) {
    components = map(urlComponents, val => val.replace(/^\/|\/$/g, '')).join('/')
  } else components = urlComponents

  const query = encodeToQueryString(queryComponents)

  if (components.length) constructed = `${baseUrl}/${components.replace(/^\//g, '')}`
  if (query.length) constructed += (constructed.indexOf('?') === -1 ? '?' : '&') + query

  return constructed
}

/**
 * Exists since we really can't rely on jQuery being everywhere we need MAPI communication.
 *
 * @param baseUrl string URL, *without query string*, to hit.
 * @param httpMethod string "GET", "POST", "PUT"
 * @param opts object Supports:
 * {
 *   headers: {'Content-Type': 'application/json'}, // Object representing header data
 *   accept: 'json', // Tries to parse response as JSON, regardless of incoming content type
 *   type: 'json', // Encodes the incoming data as JSON if POST or PUT, rather than form-encoded
 *   data: {some: 'data', to: 'use'}, // Forms the query string if GET, post data if POST or PUT
 *   success: function(response, xhr) {} // Success callback. Response is parsed response text if possible
 *   fail: function(response, xhr) {} // Failure callback. Response is parsed response text if possible
 * }
 * @returns {Promise}
 */
function ajax(baseUrl, httpMethod, opts) {
  const options = opts || {}
  const defaults = ajaxDefaults[httpMethod]

  if (!defaults) throw new Error(`Invalid HTTP method: ${httpMethod}`)

  for (const key of Object.keys(defaults)) {
    if (options[key] === undefined) options[key] = defaults[key]
  }

  let data = options.data || ''
  let url = baseUrl
  const xhr = new XMLHttpRequest()

  if (httpMethod === 'GET') {
    const query = encodeToQueryString(data)
    if (query.length) url += `?${query}`
  } else if (typeof data !== 'string') {
    if (options.type === 'json') data = JSON.stringify(data)
    else data = encodeToQueryString(data)
  }

  return new Promise((resolve, reject) => {
    xhr.open(httpMethod, url, true)

    each(options.headers, (val, key) => {
      xhr.setRequestHeader(key, val)
    })

    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return

      const text = xhr.responseText
      let response

      if (xhr.getResponseHeader('Content-Type') === 'application/json' || options.accept === 'json') {
        try {
          response = JSON.parse(text)
        } catch (e) {
          reject(xhr)
        }
      }

      if (xhr.status >= 400) reject(xhr)
      else resolve(response, xhr)
    }

    xhr.onerror = () => {
      reject(xhr)
    }

    xhr.send(data)
  })
}

/**
 * Simplified method for performing a GET expecting JSON in return.
 *
 * @param url string
 * @param data object
 * @param success function
 * @param fail function
 * @returns {Promise}
 */
function getJSON(url, data, success, fail) {
  const promise = ajax(url, 'GET', {
    accept: 'json',
    data,
    success,
    fail,
  })

  if (success) promise.then(success)
  if (fail) promise.catch(fail)

  return promise
}

/**
 * Simplified method for posting JSON, expecting JSON in return.
 *
 * @param url
 * @param data
 * @param success
 * @param fail
 * @returns {Promise}
 */
function postJSON(url, data, success, fail) {
  const promise = ajax(url, 'POST', {
    accept: 'json',
    type: 'json',
    data,
    success,
    fail,
  })

  if (success) promise.then(success)
  if (fail) promise.catch(fail)

  return promise
}

/**
 * Simplified method for PUT'ing JSON, expecting JSON in return.
 *
 * @param url
 * @param data
 * @param success
 * @param fail
 * @returns {XMLHttpRequest|Promise}
 */
function putJSON(url, data, success, fail) {
  const promise = ajax(url, 'PUT', {
    accept: 'json',
    type: 'json',
    data,
    success,
    fail,
  })

  if (success) promise.then(success)
  if (fail) promise.catch(fail)

  return promise
}

/**
 * Decodes the provided query string into an object.
 * @param str
 * @returns {{}}
 */
function parseQueryString(str = '') {
  let query = str
  if (query.charAt(0) === '?') query = query.substring(1)

  const args = filter(query.split('&'))

  return zipMap(args, arg => {
    const firstEqual = arg.indexOf('=')

    if (firstEqual === -1) {
      return [arg, true]
    }

    const name = arg.substring(0, firstEqual)
    const value = arg.substring(firstEqual + 1)

    return [decodeURIComponent(name), decodeURIComponent(value)]
  })
}

/**
 * Decodes the current location's query string into an object
 * @returns {*}
 */
function parseUriQuery() {
  return parseQueryString(document.location.search)
}

function cookie(name, value, ttlSeconds, path, domain, secure) {
  const prefix = config('cookiePrefix') ? config('cookiePrefix') + name : name

  if (arguments.length > 1) {
    document.cookie = `${prefix}=${encodeURIComponent(value)}${
      ttlSeconds ? `; expires=${new Date(+new Date() + ttlSeconds * 1000).toUTCString()}` : ''
    }${path ? `; path=${path}` : ''}${domain ? `; domain=${domain}` : ''}${secure ? '; secure' : ''}`
    return document.cookie
  }

  return decodeURIComponent((`; ${document.cookie}`.split(`; ${prefix}=`)[1] || '').split(';')[0])
}

function jsonCookie(name, value, ttl, path, domain, secure) {
  if (arguments.length > 1) {
    return cookie(name, JSON.stringify(value), ttl, path, domain, secure)
  }

  try {
    return JSON.parse(cookie(name))
  } catch (e) {
    return null
  }
}

function parseDomain(location = null) {
  if (!location) {
    return global.location ? global.location.hostname : ''
  }

  try {
    const parsed = parse(location, {})
    if (!parsed) return ''
    if (parsed.href && !parsed.hostname) {
      return location.replace(/\/.*$/g, '')
    }

    return parsed.hostname
  } catch (e) {
    error('error parsing URL', e)
    return ''
  }
}

function getStrippedDomain(location = null) {
  const host = parseDomain(location)
  const split = host.split('.')
  // Subdomain support, excluding pantheon multidevs
  if (host.includes('pantheonsite') || split.length <= 2) {
    return host
  }
  return split.slice(1).join('.')
}

/**
 * Searches the data layer for the provided key; returns the last one pushed
 * @param {string} keyToFind
 * @param {object|null} dl the datalayer object to search; defaults to window.data_layer
 * @returns any
 * */
function getFromDataLayer(keyToFind, dl = null) {
  const layer = dl || window.data_layer || window.dataLayer
  if (!layer || !layer.length) return undefined

  // Get the last element out, for cases of override
  for (let i = layer.length - 1; i >= 0; i--) {
    if (typeof layer[i][keyToFind] !== 'undefined') {
      return layer[i][keyToFind]
    }
  }
  return undefined
}

/**
 * Searches the data layer for the provided keys within one object, returning that object
 * @param {string[]} keysToFind list of keys to find
 * @param {object|null} dl the datalayer object to search; defaults to window.data_layer
 * @returns any
 * */
function getObjectFromDataLayer(keysToFind, dl = null) {
  const layer = dl || window.data_layer || window.dataLayer
  if (!layer || !layer.length) return undefined

  // Get the last element out, for cases of override
  for (let i = layer.length - 1; i >= 0; i--) {
    // confirm object has all keys
    if (
      keysToFind.every(
        key =>
          // eslint-disable-next-line no-prototype-builtins
          layer[i].hasOwnProperty(key) && typeof layer[i][key] !== 'undefined',
      )
    ) {
      return layer[i]
    }
  }

  return undefined
}

export {
  ajax,
  constructUrl,
  domReady,
  encodeToQueryString,
  getJSON,
  parseUriQuery,
  parseQueryString,
  postJSON,
  putJSON,
  cookie,
  jsonCookie,
  getStrippedDomain,
  getFromDataLayer,
  getObjectFromDataLayer,
}
