import {getRequestId, updateRequest} from '../Mapi'
import {parseUriQuery} from '../web'
import {doWhenP} from '../utils'
import {getCacheItem, setCacheItem} from '../cache'

const CACHE_KEY = 'previousMsclkIds'

/**
 * Update Bing Data
 * @returns {Promise}
 */
async function updateBingData() {
  const data = parseUriQuery()
  const requestId = getRequestId()
  const msclkid = data.msclkid || null
  const cachedData = getCacheItem(CACHE_KEY) || {}

  if (!requestId || !msclkid || cachedData[msclkid]) return null

  return updateRequest(getRequestId(), {bing_data: data}).then(() => {
    cachedData[msclkid] = true
    setCacheItem(CACHE_KEY, cachedData)
  })
}

export function run() {
  return doWhenP(getRequestId).then(() => {
    updateBingData().catch(e => console.error('bing data request failed: ', e))
    window.addEventListener('popstate', updateBingData)
  })
}
