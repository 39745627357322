import {cookie} from '../web'
import {doWhenP} from '../utils'
import {getCacheItem, setCacheItem} from '../cache'
import {getRequestId, updateRequest} from '../Mapi'

export interface VwoData {
  uuid: string
  requestId: string
}

export const VWO_CACHE_KEY = 'vwoData'
export const VWO_UUID_KEY = '_vwo_uuid'

export function getVwoId(fallback = false): string | null {
  const uuid = cookie(VWO_UUID_KEY) || null

  if (!uuid && fallback) {
    return window?._vwo_uuid || null
  }

  return uuid
}

export async function sendVwoData(requestId: string, fallback = false) {
  const uuid = getVwoId(fallback)
  const cache = getCacheItem(VWO_CACHE_KEY) || {}
  const vwo: VwoData = {uuid, requestId}

  if (!uuid || Object.entries(vwo).every(([k, v]) => cache[k] === v)) return

  updateRequest(requestId, {vwo}).then(() => {
    setCacheItem(VWO_CACHE_KEY, vwo)
  })
}

export async function run(interval = 2000, maxInterval = 10000) {
  const maxRuns = Math.floor(maxInterval / interval) + 2
  let curRun = 0
  let fallback = false

  const handlePredicate = () => {
    curRun++
    fallback = curRun >= maxRuns
    return !!(getRequestId() && getVwoId(fallback))
  }

  return doWhenP(handlePredicate, interval, maxInterval)
    .then(() => sendVwoData(getRequestId(), fallback))
    .catch(e => {
      console.log('vwo update request failed: ', e)
    })
}
