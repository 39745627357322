import {getCacheItem, setCacheItem} from '../cache'
import {getRequestId, updateRequest} from '../Mapi'
import {doWhenP} from '../utils'

interface PushlyData {
  puid: string | null
}

const CACHE_KEY = 'pushlyData'

export function getPushlyData(): PushlyData {
  const user = window?.PushlySDK?.getUser()
  const puid = user?.getId() || null
  return {puid}
}

async function sendPushlyData(requestId: string) {
  const data = getPushlyData()
  const cache = getCacheItem(CACHE_KEY) || {}
  const pushly = {...data, requestId}

  if (!data.puid || (cache.requestId === requestId && cache.puid === data.puid)) return

  updateRequest(requestId, {pushly}).then(() => {
    setCacheItem(CACHE_KEY, pushly)
  })
}

function pushlyPredicate() {
  return !!(getRequestId() && window.PushlySDK && window.PushlySDK.getUser)
}

export async function run() {
  doWhenP(pushlyPredicate, 2000, 30000)
    .then(() => sendPushlyData(getRequestId()))
    .catch(e => {
      console.log('pushly update request failed: ', e)
    })
}
