import * as Env from './Env'
import {each} from './utils'

function EventEmitter() {
  this._events = {}
}

EventEmitter.prototype = {
  addListener(label, cb) {
    if (!this._events[label]) this._events[label] = []

    this._events[label].push(cb)
  },

  emit(label, ...args) {
    Env.info('Triggering event', label)

    each(this._events[label] || [], cb => {
      cb(...args)
    })
  },
}

export default EventEmitter
