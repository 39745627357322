import {doWhen} from '../utils'
import {updateRequest, getRequestId} from '../Mapi'
import {getCacheItem, setCacheItem} from '../cache'
import {cookie} from '../web'

export function adobeDataToSend() {
  const cached = getCacheItem('adobe_analytics_id')
  const cookieVal = cookie('s_vi') || ''

  if (!cookieVal) return null

  // Extract the real visitor ID; if that fails for whatever reason, just store the cookie
  // value itself.
  const matches = cookieVal.match(/\|([^[]+)\[?/)
  const visitorId = matches ? matches[1] : cookieVal

  if (cached !== visitorId) {
    setCacheItem('adobe_analytics_id', visitorId)
    return visitorId
  }

  return null
}

export function run() {
  doWhen(
    () => getRequestId() && !!cookie('s_vi'),
    () => {
      const visitorId = adobeDataToSend()
      const requestId = getRequestId()

      if (!visitorId) return

      updateRequest(requestId, {
        adobe_analytics: {
          request_id: requestId,
          visitor_id: visitorId,
        },
      })
    },
    1000,
    20000,
  )
}
