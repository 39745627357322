import {parseUriQuery, jsonCookie, getStrippedDomain} from '../web'
import {doWhen} from '../utils'
import {updateRequest, getRequestId} from '../Mapi'

function getTidRecord() {
  const query = parseUriQuery()

  if (query.tid && query.tprovider) {
    const tprovider = query.tprovider.toLowerCase()
    if (tprovider === 'cj') {
      return {
        tid: query.tid,
        tprovider,
        tkeyword: query.tkeyword,
      }
    }
  }

  return jsonCookie('cj_tid')
}

function cacheTidRecord(adComData, daysToCache) {
  // the jsonCookie store requires the expiry provided in seconds
  const expiry = 84000 * daysToCache
  jsonCookie('cj_tid', adComData, expiry, '/', getStrippedDomain())
}

export function run() {
  doWhen(
    () => getRequestId(),
    () => {
      const adComData = getTidRecord()
      if (!adComData) {
        return
      }

      updateRequest(
        getRequestId(),
        {
          adcom_data: adComData,
        },
        () => {
          cacheTidRecord(adComData, 45)
        },
      )
    },
  )
}
