import {jsonCookie, cookie} from './web'
// 30 minutes, in milliseconds
const expirationTime = 30 * 60 * 1000
const cachedVar = {}

function allCache(key, value) {
  if (value) {
    cachedVar[key] = value
    jsonCookie(key, value, expirationTime)

    if (!global.localStorage) return null
    global.localStorage.setItem(key, JSON.stringify(value))

    return null
  }

  if (!global.localStorage) {
    return cachedVar[key] || jsonCookie(key) || JSON.parse(global.localStorage.getItem(key))
  }

  return cachedVar[key] || jsonCookie(key)
}

function setFullCache(cache) {
  const cur = global.localStorage.getItem('mapi')
  const serialized = JSON.stringify(cache)
  if (cur !== serialized) {
    global.localStorage.setItem('mapi', serialized)
  }
}

function invalidateCache() {
  setFullCache({})
}

function getMapiCache() {
  const mapiCache = global.localStorage.getItem('mapi')
  if (!mapiCache) return {}

  const cache = JSON.parse(mapiCache)
  if (cache && cache.lastWrite) {
    const {lastWrite} = cache
    const now = new Date().getTime()

    if (now - lastWrite > expirationTime) {
      invalidateCache()
      return {}
    }
  }

  return cache
}

/**
 * Store the provided item in local storage, if available, otherwise the cookie.
 *
 * @param cacheKey Key for the item.
 * @param item mixed Whatever you want to store, it'll be JSON encoded.
 */
function setCacheItem(cacheKey, item) {
  const mapiCache = getMapiCache()
  mapiCache[cacheKey] = item
  mapiCache.lastWrite = new Date().getTime()

  setFullCache(mapiCache)
}

/**
 * Get the item out of local storage if available, otherwise the cookie.
 *
 * @param cacheKey
 * @returns {*}
 */
function getCacheItem(cacheKey) {
  return getMapiCache()[cacheKey]
}

function clearCacheItem(key) {
  const cache = getMapiCache()

  if (Object.prototype.hasOwnProperty.call(cache, key)) {
    delete cache[key]
    setFullCache(cache)
  }
}

function getCookieValue(key) {
  return cookie(key)
}

export {getCacheItem, setCacheItem, getMapiCache, invalidateCache, getCookieValue, clearCacheItem, allCache}
