import {updateRequest, getRequestId} from '../Mapi'
import {getCacheItem, setCacheItem} from '../cache'
import {doWhenP} from '../utils'
import {info, error} from '../Env'

function getTestEvents() {
  const dl = global.dataLayer || []
  const events = {}
  for (let i = 0; i < dl.length; i++) {
    const row = dl[i]
    if (row && row.event === 'dynamic_content' && row.test_id) {
      events[row.test_id] = row
    }
  }
  return events
}

/** @returns {boolean} */
function handlePredicate() {
  return Object.values(getTestEvents()).length > 0 && getRequestId()
}

export function run() {
  return doWhenP(handlePredicate, 5000, 15000)
    .then(requestId => {
      const events = getTestEvents()
      const path = global.location.pathname
      const toSend = {}
      const sent = getCacheItem('dynamic_pages_tests') || {}

      Object.values(events).forEach(ev => {
        const key = `${requestId}-${path}-${ev.test_id}`
        if (sent[key]) {
          return
        }

        sent[key] = ev
        toSend[key] = {
          request_id: requestId,
          url_path: path,
          variant_key: ev.variant,
          ad_group_id: ev.adgroupid,
          insertion_term: ev.insertion_term,
          test_id: ev.test_id,
        }
      })

      const pageData = Object.values(toSend)
      info('in DynamicLandingPage', requestId, getRequestId(), events, pageData)
      if (pageData.length === 0) {
        return {}
      }

      return updateRequest(requestId, {
        dynamic_page: pageData,
      })
        .then(r => {
          info('in updateRequest then', getRequestId(), requestId, pageData)
          setCacheItem('dynamic_pages_tests', sent)
          return r
        })
        .catch(e => {
          error('error sending dynamic pages information', e)
        })
    })
    .catch(e => {
      console.log('dynamic pages request failed ', e)
    })
}
