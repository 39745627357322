function identity(thing) {
  return thing
}

/**
 * Map cb over the given collection. Big reason is to simplify dealing with objects.
 *
 * @param col Can be an object, or anything array-like (can get by index, has 'length' param),
 * @param cb function Signature cb(value, key, col)
 * @param ctx {*} Context to call cb in (that is, the 'this' parameter). In case you don't wanna use 'bind'.
 *
 * @returns {Array}
 */
function map(col, cb = identity, ctx = null) {
  const result = []

  if (!col) return result

  if (col && col.length !== undefined) {
    for (let i = 0; i < col.length; i++) {
      result.push(cb.call(ctx, col[i], i, col))
    }
  } else {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(col)) {
      result.push(cb.call(ctx, col[key], key, col))
    }
  }

  return result
}

/**
 * Call cb for each element of the collection.
 *
 * @param col Can be an object, or anything array-like (can get by index, has 'length' param),
 * @param cb function Signature cb(value, key, col)
 * @param ctx {*} Context to call cb in (that is, the 'this' parameter). In case you don't wanna use 'bind'.
 */
function each(col, cb, ctx) {
  if (!col) return

  if (col && col.length !== undefined) {
    for (let i = 0; i < col.length; i++) {
      cb.call(ctx, col[i], i, col)
    }
  } else {
    for (const key of Object.keys(col)) {
      cb.call(ctx, col[key], key, col)
    }
  }
}

function filter(col, cb = identity, ctx = null) {
  if (!col) return []
  let result

  if (col && col.length !== undefined) {
    result = []
    for (let i = 0; i < col.length; i++) {
      if (cb.call(ctx, col[i], i, col)) {
        result.push(col[i])
      }
    }
  } else {
    result = {}
    for (const key of Object.keys(col)) {
      if (cb.call(ctx, col[key], key, col)) {
        result[key] = col[key]
      }
    }
  }

  return result
}

/**
 * Get the index of the first element of the collection that returns a truthy value for cb.
 *
 * @param col array | {}
 * @param cb (item, index, col) => mixed Defaults to the identity function
 * @param ctx Context to run cb in
 * @returns {*}
 */
function firstIndex(col, cb = identity, ctx = null) {
  if (!col) return undefined

  if (col && col.length !== undefined) {
    for (let i = 0; i < col.length; i++) {
      if (cb.call(ctx, col[i], i, col)) {
        return i
      }
    }
  } else {
    for (const key of Object.keys(col)) {
      if (cb.call(ctx, col[key], key, col)) {
        return key
      }
    }
  }

  return undefined
}

/**
 * Get the first element of the collection that returns a truthy value for cb.
 *
 * @param col array | {}
 * @param cb (item, index, col) => mixed Defaults to the identity function
 * @param ctx Context to run cb in
 * @returns {*}
 */
function first(col, cb = identity, ctx = null) {
  if (!col) return undefined
  const index = firstIndex(col, cb, ctx)
  return index === undefined ? undefined : col[index]
}

/**
 * See if any element of the collection returns a truthy value for cb.
 *
 * @param col array | {}
 * @param cb (item, index, col) => mixed Defaults to the identity function
 * @param ctx Context to run cb in
 * @returns {*}
 */
function any(col, cb = identity, ctx = null) {
  if (!col) return false
  return first(col, cb, ctx) !== undefined
}

/**
 * Given an array of pairs, return an object with the first pair item as the key, second as the value.
 * @param pairs
 * @returns {{}}
 */
function zip(pairs) {
  const result = {}
  if (!pairs || !pairs.length) return result

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i]
    // eslint-disable-next-line no-continue
    if (!pair || pair.length !== 2) continue

    // eslint-disable-next-line prefer-destructuring
    result[pair[0]] = pair[1]
  }

  return result
}

/**
 * Create an object out of the mapped collection of pairs.
 *
 * @param col
 * @param cb
 * @param ctx
 * @returns {{}}
 */
function zipMap(col, cb, ctx) {
  return zip(map(col, cb, ctx))
}

function isOf(thing, type) {
  return Object.prototype.toString.call(thing) === `[object ${type}]`
}

function getPath(obj, pathString, defaultValue) {
  const path = isOf(pathString, 'Array') ? pathString : (pathString || '').split('.')

  let current = obj
  for (let i = 0; i < path.length; i++) {
    const key = path[i]

    // Objects and arrays are all that's supported, they both return 'object' here
    if (typeof current !== 'object') {
      return defaultValue
    }

    if (typeof current[key] !== 'undefined') {
      current = current[key]
      // eslint-disable-next-line no-continue
      continue
    }

    return defaultValue
  }

  return current
}

function config(key = null, defaultValue = null) {
  return getPath(global, `MAPI.config.${key}`, defaultValue)
}

/**
 * Perform the 'action' function when 'predicate' returns a truthy value.
 * @param predicate function
 * @param action function
 * @param interval int Interval to wait between predicate checks.
 * @param maxWait int Maximum amount of time to wait for predicate to be true
 * @param callAfterExpiration bool Call the action after maxWait. true to call, false otherwise
 */
function doWhen(predicate, action, interval = 500, maxWait = null, callAfterExpiration = false) {
  let waited = 0

  // If currently true, just call without a setInterval
  if (predicate.call()) {
    action.call()
    return
  }

  const intId = setInterval(() => {
    waited += interval

    if (predicate.call()) {
      clearInterval(intId)
      action.call()
    }

    if (maxWait && waited >= maxWait) {
      clearInterval(intId)
      if (callAfterExpiration) action.call()
    }
  }, interval)
}

function fromDatalayer(fieldName) {
  const dl = global.dataLayer

  if (!dl) return null
  if (!dl.length) return null

  for (let i = 0; i < dl.length; i++) {
    const el = dl[i] || {}
    if (el[fieldName]) return el[fieldName]
  }

  return null
}

function isString(thing) {
  return isOf(thing, 'String')
}

function isFunction(thing) {
  return isOf(thing, 'Function')
}

function toArray(thing) {
  if (isOf(thing, 'Array')) return thing
  if (!thing) return []
  return [thing]
}

/**
 * "Empty" here is defined as values we don't want to overwrite previous
 * values in the datalayer. Literal 0 or false should be allowed to overwrite.
 */
function isEmpty(thing) {
  return thing === '' || thing === null || thing === undefined
}

/**
 * Return a promise that resolves when 'predicate' returns a truthy value.
 *
 * @param {function(): any} predicate
 * @param {int} [interval] Interval to wait between predicate checks.
 * @param {int} [maxWait] Maximum amount of time to wait for predicate to be true
 * @param {boolean} [callAfterExpiration] Call the action after maxWait. true to call, false otherwise
 */
function doWhenP(predicate, intervalArg = 500, maxWait = 0, callAfterExpiration = false) {
  let waited = 0
  const interval = intervalArg || 500

  // If currently true, just call without a setInterval
  const res = predicate.call()
  if (res) {
    return Promise.resolve(res)
  }

  return new Promise((resolve, reject) => {
    const intId = setInterval(() => {
      waited += interval

      const result = predicate.call()
      if (result) {
        clearInterval(intId)
        resolve(result)
      }

      if (maxWait && waited > maxWait) {
        clearInterval(intId)
        if (callAfterExpiration) {
          resolve()
        } else {
          reject(new Error('expiration'))
        }
      }
    }, interval)
  })
}

function loadScript(url, cb) {
  // eslint-disable-next-line no-undef
  const head = document.getElementsByTagName('head')[0] || document.documentElement
  // eslint-disable-next-line no-undef
  const script = document.createElement('script')
  script.src = url

  let done = false

  if (cb) {
    const loadCb = function loadCb() {
      if (done) return
      const state = this.readyState
      if (state && state !== 'loaded' && state !== 'complete') return

      done = true
      if (cb) cb.call()

      // Handle memory leak in IE
      script.onload = null
      script.onreadystatechange = null
      if (head && script.parentNode) {
        head.removeChild(script)
      }
    }
    script.onload = loadCb.bind(script)
    script.onreadystatechange = loadCb.bind(script)
  }

  // Use insertBefore instead of appendChild  to circumvent an IE6 bug.
  head.insertBefore(script, head.firstChild)
}

export {
  getPath as get,
  config,
  each,
  filter,
  map,
  zip,
  zipMap,
  getPath,
  doWhen,
  doWhenP,
  fromDatalayer,
  isFunction,
  isOf,
  first,
  firstIndex,
  isString,
  isEmpty,
  toArray,
  any,
  loadScript,
}
