import {getRequestId, updateRequest} from '../Mapi'
import {info} from '../Env'
import {parseUriQuery} from '../web'
import {doWhenP, get} from '../utils'
import {getCacheItem, setCacheItem} from '../cache'

export function run(isUpdate, expiration = 10000, interval = 500) {
  return doWhenP(() => parseUriQuery().sms_optin_id && getRequestId(), interval, expiration, false)
    .then(requestId => {
      const query = parseUriQuery()
      const sent = getCacheItem('sentSmsIds') || {}
      const smsId = get(query, 'sms_id', '')
      const smsOptinId = get(query, 'sms_optin_id', '')

      if (!smsOptinId || sent[`${smsId}:${smsOptinId}`]) {
        return Promise.resolve({})
      }

      return updateRequest(requestId, {
        sms_opt_in: {
          request_id: requestId,
          sms_id: smsId,
          sms_optin_id: smsOptinId,
        },
      }).then(() => {
        const sentIds = getCacheItem('sentSmsIds') || {}
        sentIds[`${smsId}:${smsOptinId}`] = true
        setCacheItem('sentSmsIds', sentIds)
      })
    })
    .catch(e => {
      info(`error sending SmsOptIn: ${e}`)
      return e
    })
}
