import promise from 'promise-polyfill'

import 'core-js/actual/array/from'
import 'core-js/actual/function/bind'
import 'core-js/actual/object/keys'
import 'core-js/actual/object/values'

if (!global.Promise) {
  global.Promise = promise
}
