import {getPromoCode, getRequestId} from '../Mapi'

function gen(targetUrl, rid, kbid) {
  if (
    !targetUrl.searchParams.has('kbid') ||
    !targetUrl.searchParams.has('cl_system') ||
    !targetUrl.searchParams.has('cl_system_id') ||
    !targetUrl.searchParams.has('clreqid')
  ) {
    targetUrl.searchParams.set('cl_system', 'mapi')
    targetUrl.searchParams.set('cl_system_id', rid)
    targetUrl.searchParams.set('clreqid', rid)
    targetUrl.searchParams.set('kbid', kbid)
    return targetUrl
  }
  return null
}

function run() {
  document.body.addEventListener('click', e => {
    // We need to account for links that are nested
    const targetElement = e.target.closest('a')
    if (!targetElement) {
      return
    }

    const href = targetElement.getAttribute('href')

    if (!href || !href.match(/^http/)) {
      return
    }

    const targetUrl = new URL(targetElement.href)
    const {host} = window.location

    if (host === targetUrl.hostname) {
      return
    }

    const rid = getRequestId()
    const kbid = getPromoCode()
    const url = gen(targetUrl, rid, kbid)

    if (!url) {
      return
    }

    e.preventDefault()
    window.open(url.href, targetElement.target)
  })
}

export {run, gen}
